import "minireset.css";
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

export const ArtistEmailTemplate = ({
  emailBannerImage
}) => {
  return (
    <div style={{ marginLeft: '30px', marginTop: '30px' }}>
      <h1>Banner Image Url:</h1>
      <p style={{ padding: '12px' }}>{emailBannerImage}</p>
      <img src={emailBannerImage} />
    </div>
  );
};

ArtistEmailTemplate.propTypes = {
  emailBannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const ArtistEmail = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  // Normalizes image src, since preview sometimes sends a string rather than object
  // TODO should move this outside the component
  const emailBannerSrc =
    typeof frontmatter.emailStyles.emailBannerImage === "string"
      ? frontmatter.emailStyles.emailBannerImage 
      : frontmatter.emailStyles.emailBannerImage.childImageSharp.fluid.src;

  return (
    <Layout>
      <ArtistEmailTemplate
        emailBannerImage={emailBannerSrc}
      />
    </Layout>
  );
};

ArtistEmail.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ArtistEmail;

export const artistEmailQuery = graphql`
  query ArtistEmail($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        emailStyles {
          emailBannerImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
